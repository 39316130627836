import other from './other';
import dashboard from './dashboard';
// import widget from './widget';
// import magazine from './magazine';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = (isPermitted = false) => {
    const items = isPermitted ? [dashboard, other] : [dashboard];
    return { items };
};

export default menuItems;
