import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDeviceTvOff, IconDeviceTvOld, IconMenu, IconNews, IconReportAnalytics, IconWorldWww } from '@tabler/icons';

// ===========================|| NEWS WEBSITES PAGE & NEWS ARTICLE ITEMS ||=========================== //

const other = {
    id: 'news_websites-roadmap',
    type: 'group',
    children: [
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'item',
            url: '/reports',
            icon: IconReportAnalytics,
            breadcrumbs: false
        },
        {
            id: 'models',
            title: <FormattedMessage id="models" />,
            type: 'collapse',
            icon: IconMenu,
            children: [
                {
                    id: 'keywords',
                    title: <FormattedMessage id="keywords" />,
                    type: 'item',
                    url: '/keywords',
                    breadcrumbs: false
                },
                {
                    id: 'vips',
                    title: <FormattedMessage id="vips" />,
                    type: 'item',
                    url: '/page/vip/list',
                    breadcrumbs: false
                },
                {
                    id: 'persons',
                    title: <FormattedMessage id="persons" />,
                    type: 'item',
                    url: '/page/person/list',
                    breadcrumbs: false
                },
                {
                    id: 'newsTypes',
                    title: <FormattedMessage id="newsTypes" />,
                    type: 'item',
                    url: '/page/newsType/list',
                    breadcrumbs: false
                },
                {
                    id: 'contentTypes',
                    title: <FormattedMessage id="contentTypes" />,
                    type: 'item',
                    url: '/page/contentType/list',
                    breadcrumbs: false
                },
                {
                    id: 'contentSubjects',
                    title: <FormattedMessage id="contentSubjects" />,
                    type: 'item',
                    url: '/page/contentSubject/list',
                    breadcrumbs: false
                },
                {
                    id: 'tags',
                    title: <FormattedMessage id="tags" />,
                    type: 'item',
                    url: '/page/tag/list',
                    breadcrumbs: false
                },
                {
                    id: 'politicalParties',
                    title: <FormattedMessage id="politicalParties" />,
                    type: 'item',
                    url: '/page/politicalParty/list',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'news_websites',
            title: <FormattedMessage id="news_websites" />,
            type: 'item',
            url: '/news_websites',
            icon: IconWorldWww,
            breadcrumbs: false
        },
        {
            id: 'newspapers',
            title: <FormattedMessage id="newspapers" />,
            type: 'item',
            url: '/newspapers',
            icon: IconNews,
            breadcrumbs: false
        },
        {
            id: 'tvs',
            title: <FormattedMessage id="tvs" />,
            type: 'item',
            url: '/tv_list',
            icon: IconDeviceTvOld,
            breadcrumbs: false
        },
        {
            id: 'tvArchives',
            title: <FormattedMessage id="tvArchives" />,
            type: 'item',
            url: '/tv_archives',
            icon: IconDeviceTvOff,
            breadcrumbs: false
        }
    ],
};

export default other;
